//========================VARIABLES ONLY FOR castom.sass ===========================
$rootpath                   : "";
$color1                     : #fff;
$color2                     : #4DA6FF;
$color3                     : white;
$color4                     : #F5F6F8;
$color5                     : #9B59B6;
$color6                     : #efefef;
$color7                     : #f3f3f3;
$color8                     : #FF4D4D;
$color_ahtung               : red;
$tintcolor                  : lighten( desaturate($color1, 20%), 20%);
$leftmenuwidth              : 230px;
$leftmenu_navsm_width       : 70px;
$footerheight               : 50px;
$text-color                 : #73879C;
$text-color2                : $color2;

//---------------------------Role-colors-------------------------------
$color_admin                : #337AB7;
$color_chief                : #26B99A;
$color_client_manager       : #5BC0DE;
$color_economist            : #F0AD4E;
$color_engineer_assistant   : #D9534F;
$color_echolocation_engineer: #4B5F71;
$color_ultrasound_engineer  : #9966CC;
$color_statistical_engineer : #996633;
$color_rare_tests_engineer  : #CC3300;
$color_commercial_director  : #A25588;
$color_office_manager       : #FAF;
$color_accountant           : #9F9;
$color_hr                   : #999;

$color_chief_engineer       : #9966CC;
$color_foreman              : #996633;

//---------------------------diff-label-colors-------------------------------
$labels_color :(
  "20"  : #336,
  "21"  : #55f,
  "22"  : #363,
  "23"  : #707,
  "24"  : #033,
  "25"  : #533,
  "26"  : #663,
  "27"  : #099,
  "28"  : #636,
  "29"  : #303,
  "30"  : #077,
  "31"  : #770,
  "32"  : #225,
  "33"  : #377,
  "34"  : #330,
  "35"  : #232,
  "36"  : #848,
  "37"  : #375,
  "38"  : #143
);
//---------------------------Role-client-colors---------------------------
$client_color_admin    		:$color_admin;
$client_color_chief         :$color_chief;
$client_color_pto           :$color_ultrasound_engineer;
$client_color_district_chief:$color_statistical_engineer;
$client_color_accountant    :$color_accountant;


//---------------------------Icone-task-colors-------------------------------
$color_deadline_hot          : red;
$color_deadline_norm         : orange;
$color_deadline_cool         : darkgreen;
$color_task_done             : gray;


//---------------------------bootstrap - screen width -------------------------------
// Extra small screen / phone
$screen-xs                  : 480px;
// Small screen / tablet
$screen-sm                  : 768px;
// Medium screen / laptop
$screen-md                  : 992px;
// Large screen / desktop wide laptop
$screen-lg                  : 1200px;
// Large screen / wide desktop
$screen-xlg                  : 1920px;

$screen-xxs-max             : ($screen-xs - 1);
$screen-xs-max              : ($screen-sm - 1);
$screen-sm-max              : ($screen-md - 1);
$screen-md-max              : ($screen-lg - 1);
$screen-lg-max              : ($screen-xlg - 1);
