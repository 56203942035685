#modal-print-journal{
  .modal-dialog{
    max-width: 100%;
    width: 99%;
  }
}

iframe[id^="blankeditor"]{
  html {
    border: solid 1px white;
    width: 90% !important;
    margin: 0 auto;
  }
}
