$text-color                 : #73879C;
$text-color2                : $color2;
$cfa_color                  : #FE6000;
$bns_color                  : #6D00D9;

$badge-work-type-width: 90px; // ширина бейджа work-type
.object_worktype {
  //cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, .2);
  .x_title {
    margin-bottom: 0;
  }

  h2 {
    //padding-right: $badge-work-type-width;
    padding-right: 20px;
  }
  .description {
    //margin-top: -11px;
    padding: 3px 0px;
    font-size: .85em;
    text-align: justify;
    height: 4.4em;
    overflow-y: hidden;
  }
  .value {
    font-weight: 700
  }
  .data {
    text-align: right;
    white-space: nowrap;
  }
  .ico {
    padding-left: 10px;
    font-size: 1.25em;
  }

  .doc-list {
    tr:hover td {
      background-color: transparent
    }
  }
  &:hover {
    border-color: #7e7e7e;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, .7);
    .btn-group {
      //.fa:before, .fa:after{
      //  color: #fff !important;
      //}
      .put-to-box:after {
        text-shadow: -1px -1px 0.02px buttonface, 1px -1px 0.02px buttonface, -1px 1px 0.02px buttonface, 1px 1px 0.02px buttonface;
      }
      .btn {
        //background-color: $color2;
        //color: #fff;
        &:hover {
          background-color: darken($color2, 5%);
          border-color: darken($color2, 9%);
          color: #fff;
          .fa:before, .fa:after {
            color: #fff !important;
          }
        }
        &.btn-primary {
          background-color: #337ab7;
          border-color: #2e6da4;
          color: #fff;
          .fa:before, .fa:after {
            color: #fff !important;
          }
          &:hover {
            background-color: #286090;
            border-color: #204d74;
          }
        }
      }
    }
    .x_footer {
      .collapse-link {
        color: $color2;
      }
    }
  }
}

.x_footer {
  .collapse-link {
    cursor: pointer;
    .content {
      margin-left: .7em;
    }
    .fa-chevron-up + .content:after {
      content: "Згорнути";
    }
    .fa-chevron-down + .content:after {
      content: "Інформація про об'єкт";
    }
  }
}

.btn-group {
  .btn {
    box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.2);
    .fa:before, .fa:after {
      color: $text-color !important;
    }
    &.btn-sm {
      .fa {
        font-size: 1.15em;
      }
    }
    &.btn-primary {
      color: $text-color;
      background-color: #ddd;
      border-color: transparent;
    }
  }
}

@media screen and (max-width: $screen-xxs-max) {
  .actions-btn .btn-group {
    .btn {
      margin: 6px;
      display: block;
      width: 100% !important;
      float: none;
      &.btn-round {
        border-radius: 30px !important;
      }
    }
  }
}

.actions-btn {
  margin: 15px -20px;
}

%btn-expand {
  margin: 15px 0;
  .btn-group {
    width: 100%
  }
  .btn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@for $i from 1 through 12 {
  .btn-expand-#{$i} {
    @extend %btn-expand;
    .btn {
      width: 100% / $i !important;
    }
  }
}

.breadcrumbs {
  span {
    color: $text-color2;
    margin-left: 6px;
  }
  a {
    color: $color2;
    margin-right: 6px;
    &:hover {
      color: $text-color2;
    }
  }
  & + h3 {
    color: $color2 !important;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
  }
}

.metadata, .journals {
  margin: 15px 0;
}

.work-type {
  display: inline-block;
  background-color: transparent;
  position: absolute;
  right: 0;
  font-size: 16px;
  border-radius: 2em;
  line-height: 1.8em;
  padding: 0 .8em 0 .4em;
  width: $badge-work-type-width;
  //color: #fff !important;
  white-space: nowrap;
}

div.work-type {
  position: relative;
  border-radius: 0;
  padding: 0;
  display: block;
  width: 100%;
  &:before {
    margin-right: 10px
  }
  background-color: transparent;
  &.cfa {
    color: $cfa_color;
  }
  &.bns {
    color: $bns_color;
  }
}

.value.work-type {
  position: relative;
  width: auto;
}

//-----new work-type icons --------------------------
$worktype_btn_size: 20px; //габарити батона (20)
$worktype_btn_in_x: 2; //кількість іконок у спрайті ico-work-type.png (40px*40px)
$worktype_btn_in_y: 4; //кількість іконок у спрайті ico-work-type.png (40px*40px)
%worktype_custom_icones:before {
  background: url($rootpath + "/images/ico-work-type.png?version=2018") no-repeat;
  content: '';
  background-size: $worktype_btn_size * $worktype_btn_in_x;
  width: $worktype_btn_size;
  height: $worktype_btn_size;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 5px;
  //margin-bottom: 16px - $btn_size;
}

.work-type {
  &.cfa { // буроінєкційні палі
    @extend %worktype_custom_icones;
    //background-color: $cfa_color;
    &:before {
      background-position-x: 0 * $worktype_btn_size;
      background-position-y: 0 * $worktype_btn_size !important;
    }
  }
  &.bns { // буронабивні палі
    @extend %worktype_custom_icones;
    //background-color: $bns_color ;
    &:before {
      background-position-x: -1 * $worktype_btn_size;
      background-position-y: 0 * $worktype_btn_size !important;
    }
  }
}

@media screen and (max-width: $screen-xs-max) {
  .doc-list .status .badge {
    max-width: 61px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.status {
  .badge {
    &.not-created { //Не создан
      background-color: #fff;
      border-color: #777;
      color: #777;
    }
    &.in-work { //В работе
      background-color: #777;
      border-color: #777;
      color: #fff;
    }
    &.on-statement { //На утверждении
      background-color: #fff;
      border-color: #F39C12;
      color: #F39C12;
    }
    &.approved { //Утвержден
      background-color: #F39C12;
      border-color: #F39C12;
      color: #fff;
    }
    &.signed { //Подписан
      background-color: #1ABB9C;
      border-color: #1ABB9C;
      color: #fff;
    }
  }
}

.fa-male-o,
.fa-female-o {
  font-size: 1.08em;
  &:before {
    color: #fff;
    font-family: 'FontAwesome';
    text-shadow: -1px -1px 0px $text-color, 1px -1px 0px $text-color, 1px 1px 0px $text-color, -1px 1px 0px $text-color, 0px 1px 0px $text-color, 0px -1px 0px $text-color;
  }
  &.green:before {
    text-shadow: -1px -1px 0px #1ABB9C, 1px -1px 0px #1ABB9C, 1px 1px 0px #1ABB9C, -1px 1px 0px #1ABB9C, 0px 1px 0px #1ABB9C, 0px -1px 0px #1ABB9C;;
  }
}

.fa-male-o:before {
  content: '\f183';
}

.fa-female-o:before {
  content: '\f182';
}

@media (min-width: $screen-lg) and (max-width: $screen-lg-max) {
  .page-title__metadatas li {
    width: 33%;
    padding: 10px 30px
  }
}

@media (max-width: $screen-md-max) {
  .page-title__metadatas li {
    width: 100%;
    padding: 10px 20px;
    label{
      width: 30%;
    }
  }
}


.page-title__metadatas {
  list-style: none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  border-bottom: 1px solid #eee;
  li {
    display: flex;
    //justify-content: space-between;
    margin-bottom: -1px;
    //border-bottom: 1px solid #bbb;
    //width: 25%;
    padding: 10px 40px;
    float: left;
    label {
      font-weight: normal;
      margin-right: 15px
    }
    .value {
      font-weight: bold;
      color: $color2;
      &:after {
        font-family: FontAwesome;
        content: '\f040';
        margin-left: 15px;
        color: transparent;
      }
    }

    &:hover {
      .value:after {
        color: $color2;
      }
    }
  }
}

%overflow_ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.overflow_ellipsis {
  @extend %overflow_ellipsis
}

.expand_col {
  max-width: 20em;
}

.modal .x_panel {
  background-color: #f1f1f1;
  box-shadow:none;
  .ln_solid {
    border-top-color: $color_modal_xpanel_line;
  }
  .x_title {
    // border-bottom-color:$color_modal_xpanel_line;
  }
  .x_panel {
    background-color: #fafafa;
  }
}

.s_val {
  margin-right: 10px;
  border-radius: .5em .5em 0 .5em;
}

.s_unit {
  font-size: .8em;
  bottom: 0em;
  right: -2px;
  padding: 2px 3px;
  border-radius: .5em .5em .5em 0;
}

//----------new widget print journal-----------------
.actions-btn button {margin: 0}
.actions-btn button .fa{margin-right: .4em}
.fa-file-pdf-o {
  color: red !important;
}
.vertical-center{
  top: 50%;
  position: absolute;
  right: 1em;
}

.journal_ico{
  display: inline-block;
  font-size: 6em;
  width: 1em;
  height: 1em;
  background-size: contain;

  &.hidden_work_acts2{
    background-image: url('/images/ico_act_arm.png');
  }
  &.hidden_work_acts{
    background-image: url('/images/ico_act_drill.png');
  }
  &.pile_field_act{
    background-image: url('/images/ico_act_pile_field.png');
  }
  &.zhbip{
    background-image: url('/images/ico_jouranl_CFA.png');
  }
  &.zhbns{
    background-image: url('/images/ico_jouranl_BNS.png');
  }
  &.zhbr{
    background-image: url('/images/ico_jouranl_CW.png');
  }
  &.pile_echo_protocols{
    background-image: url('/images/ico_pile_protocol.png');
  }
  &.cube_protocols{
    background-image: url('/images/ico_cube_protocol.png');
  }
  &.pile_graph{
    background-image: url('/images/ico_pile_graph.png');
  }
  &.additions{
    background-image: url('/images/ico_additions.png');
  }
  &.concrete_passports{
    background-image: url('/images/ico_concrete_passports.png');
  }
  &.concrete_passports_register{
    background-image: url('/images/ico_concrete_passports_register.png');
  }
  &.carcass_passports{
    background-image: url('/images/ico_carcass_passports.png');
  }
  &.metal_certificates{
    background-image: url('/images/ico_metal_certificates.png');
  }
}

.btn.badge-filter{
  border: 1px solid $cfa_color;
  background-color: #fff;
  color: $cfa_color;
  margin-top: 10px;
  &:before{
    font-family: FontAwesome;
    content: '\f096';
    margin-right: 5px;
  }
  span{
    margin-left: 3px !important;
  }
  .badge{
    background: $cfa_color;
    color: #fff;
  }
  &:hover{
    background: lighten($cfa_color,30%);
    color: $cfa_color;
  }
  &.active{
    background: $cfa_color;
    color: #fff;
    &:before{
      content: '\f14a';
    }
    .badge{
      background: #fff;
      color: $cfa_color;
    }
  }
}

.badge_filter_wrapper{
  display: inline-block;
  vertical-align: bottom;
}

li[role="presentation"]{
  height: 36px;
}
ul.nav-tabs .badge-filter{
  margin-left: 3px !important;
  color: #fff;
}
ul.nav-tabs li.active .badge-filter{
  background-color: white !important;
  color: #5A738E !important;
}


@media (max-width: 2550px) {
  .has_tabs{
    .table-filter {
      margin-top: 25px;
    }
    ul.bar_tabs {
      border-bottom: none;
      margin: 21px 0 25px;
      background-color: transparent;
      li, a {
        border: none !important;
      }
      & > li {
        margin: 4px 8px;
        background-color: transparent;
        &.active {
          margin: 4px 8px;
          a, a:focus {
            background-color: $cfa_color;
            color: #fff;
          }
        }
        a {
          padding: 8px;
          border-radius: 3em;
        }
      }
    }
  }

}

@media (max-width: 1480px) {
  .btn-group.split-xxs .btn.btn-round{
    margin: 10px 0px;
    display: block;
    width: 100% !important;
    float: none;
    border-radius: 30px !important;
  }
  .vertical-center{
    top: unset;
    bottom: 25px;
  }
}
@media (max-width: 580px){
  .vertical-center{
    right: 0px;
  }
}
@media (max-width: 480px){
  .vertical-center{
    position: static;
  }
}

//------- for journals editor modal verum doc ---------
@import "_work_journal.scss";